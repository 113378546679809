// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// https://finance.dhanushinfotech.com
let apiURL: string = location.origin.includes("localhost") ? "https://finance.dhanushinfotech.com" : location.origin;

export const environment = {
  production: false,
  // serviceUrl: "https://oukdev.samvaadpro.com/api/",
  // fileUrl: "https://oukdev.samvaadpro.com/",
  // videoUrl: "https://oukdev.samvaadpro.com",
  // downloadUrl: "https://oukdev.samvaadpro.com/api",
  // SOCKET_ENDPOINT: 'http://localhost:3000',
  // SamvaadUrl: 'https://www.samvaadpro.com/conference/',
  // RazorPay_Key: 'rzp_live_FuIlkhr9QlHKMl',//Live,
  // WebSocket_URL: "https://dev.samvaadpro.com/",
  // OpenVidu_URL: "https://openvidu.dhanushinfotech.com:4443",
  // reportUrl: 'https://oukdev.samvaadpro.com/oukreports/v1/generate',
  // downloadReportUrl: 'https://oukdev.samvaadpro.com/oukreports/v1/download/'

  serviceUrl: `${apiURL}/api/`,
  fileUrl: `${apiURL}/`,
  videoUrl: `${apiURL}`,
  downloadUrl: `${apiURL}/api`,
  SOCKET_ENDPOINT: 'http://localhost:3000',
  SamvaadUrl: 'https://www.samvaadpro.com/conference/',
  RazorPay_Key: 'rzp_live_FuIlkhr9QlHKMl',//Live,
  WebSocket_URL: "https://dev.samvaadpro.com/",
  OpenVidu_URL: "https://openvidu.dhanushinfotech.com:4443",
  reportUrl: `${apiURL}/oukreports/v1/generate`,
  downloadReportUrl: `${apiURL}/oukreports/v1/download/`,
  kuppsUrl: apiURL == 'https://oukdevapplicant.samvaadpro.com' ? 'https://oukdev.samvaadpro.com' //dev
    : apiURL == 'https://oukqaapplicant.samvaadpro.com' ? 'https://oukinternal.samvaadpro.com'  //QA
      : apiURL == 'https://oukadmissionsdev.konza.go.ke' ? 'https://oukmydev.konza.go.ke' //Preprod
        : apiURL == 'https://admissions.ouk.ac.ke' ? 'https://my.ouk.ac.ke' : '' //Production
};

//http://localhost:56608/swagger/index.html   serviceUrl:"https://lmsemsqa.samvaad.pro/api/",

//  serviceUrl:"http://lmsav.dhanushinfotech.com/",https://lmsqa.dhanushinfotech.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
